<template>
  <div>
    <b-row>
      <b-col cols="12" md="3" class="pl-2"> </b-col>
      <b-col cols="12" md="3"></b-col>
      <b-col cols="12" md="3"></b-col>
      <b-col cols="12" md="3">
        <div class="flex float-right">
          <b-button
            class="mr-2 swapButton"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="white"
            @click="openSwapRequestMode()"
          >
            <feather-icon icon="RepeatIcon" class="mr-1" />
            <span v-if="swapRequestListLength === 0">Swap Requests</span>
            <span v-if="swapRequestListLength === 1"
              >Swap Request
              <span
                style="
                  background-color: #ff4848;
                  padding: 5px;
                  border-radius: 30px;
                  margin-left: 5px;
                "
                class="text-white"
                >1</span
              ></span
            >
            <span v-if="swapRequestListLength >= 2"
              >Swap Requests
              <span
                style="
                  background-color: #ff4848;
                  padding: 5px;
                  border-radius: 30px;
                  margin-left: 5px;
                "
                class="text-white"
                >{{ swapRequestListLength }}
              </span></span
            >
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.top="'Print'"
            class="btn-icon mr-1"
            variant="lighten-2"
            @click="printData()"
          >
            <feather-icon
              style="width: 20px; height: 20px"
              font-scale="2"
              icon="PrinterIcon"
            />
          </b-button>
        </div>
      </b-col>
      <b-col cols="12" md="12" class="mt-2"> </b-col>
    </b-row>

    <b-card>
      <b-row>
        <b-col cols="12" md="3">
          <b-form-group>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input id="icons-search" placeholder="Search" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col class="" cols="12" md="9">
          <b-row class="mb-1">
            <b-col md="4">
              <v-select
                v-model="selectedShiftType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="[
                  { title: 'All', value: 'all' },
                  { title: 'Vacant', value: 'vacants' },
                  { title: 'Filled', value: 'filleds' },
                  { title: 'Approved', value: 'approved' },
                  { title: 'Cancelled', value: 'cancelled' },
                ]"
                class="mb-2"
                label="title"
                placeholder="All Shifts"
              />
            </b-col>
            <b-col md="5"> </b-col>
            <b-col md="3">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="btn-icon mr-1 float-right"
                variant="primary"
                @click="openApplyShiftsModel()"
                >Vacant Shifts
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <div class="schedule-vue-sample">
        <div class="col-md-12 control-section">
          <div class="content-wrapper">
            <b-overlay :show="calendarLoading" rounded="sm">
              <ejs-schedule
                ref="scheduler"
                height="auto"
                :dataBinding="dataBinding"
                :selectedDate="selectedDate"
                :eventSettings="eventSettings"
                :eventRendered="onEventRendered"
                :quickInfoTemplates="quickInfoTemplates"
                :popupOpen="openpopup"
                :eventClick="onEventClick"
                :eventDoubleClick="onEventClick"
                :timeScale="timeScale"
                :startHour="startHour"
              >
                <e-views>
                  <!--                   <e-view :eventTemplate="weekTemplate" displayName="DAY" option="Day"/>-->
                  <e-view
                    :eventTemplate="weekTemplate"
                    displayName="WEEK"
                    option="Week"
                  />
                  <e-view
                    :eventTemplate="monthTemplate"
                    displayName="MONTH"
                    option="Month"
                  />
                </e-views>
              </ejs-schedule>
            </b-overlay>
          </div>
        </div>
      </div>
    </b-card>
    <b-modal
      id="modal-center"
      v-model="showApplyVacantModel"
      no-close-on-backdrop
      @change="updateState"
      centered
      hide-footer
      size="xl"
      title="Vacant Shifts"
    >
      <b-overlay :show="vacantShiftsLoading" rounded="sm">
        <b-row class="mb-1">
          <b-col cols="12">
            <b-alert show variant="primary">
              <div class="alert-body">
                <feather-icon class="mr-25" icon="AlertCircleIcon" />
                <span class="ml-25"
                  >All listed shifts are applicable to your role only. {{ momentFormat( new Date(),"DD-MMM-YY")}} {{  momentFormat( new Date(),"HH:mm")}}</span
                >
                <!--                <span class="ml-25">All listed shifts are applicable to your role only: Occupational Therapist.</span>-->
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <div>
          <b-card>
            <!--            <h3>Medical Practice</h3>-->
            <b-row class="mb-1">
              <b-col cols="4">
                <b-form-group>
                  <flat-pickr
                    v-model="filters['start']"
                    :config="{
                      enableTime: false,
                      noCalendar: false,
                      dateFormat: 'Y-m-d',
                      dateFormat: 'Y-m-d',
                      locale: {
                        firstDayOfWeek: 1,
                      },
                    }"
                    class="form-control"
                    placeholder="Select Date"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="8">
                <b-row class="mr-1">
                  <b-col>
                    <ul class="flex float-right list-inline mb-0">
                      <li class="list-inline-item">
                        <b-form-checkbox
                          v-model="show_vacant"
                          name="check-button"
                          switch
                          @change="stateChange"
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                      </li>
                      <li class="list-inline-item text-secondary">
                        Show vacant shifts on my calendar
                      </li>
                    </ul>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-overlay :show="tableLoading" rounded="sm">
                  <b-table
                    :current-page="currentPage"
                    :per-page="pagination.perPage"
                    :fields="fields"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    :items="getVacantShiftApplications"
                    :sort-by.sync="sortBy"
                    :eventClick="onEventClick"
                    :eventDoubleClick="onEventClick"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :popupOpen="openpopup"
                    hover
                    ref="table"
                    class="mobile_table_css"
                    responsive
                  >
                    <template #cell(action)="data">
                      <!-- <b-button variant="primary" :disabled="true" v-if="data.item.applications == 'Yes'" >Applied</b-button> -->
                      <b-badge
                        v-if="data.item.applications == 'Yes'"
                        :variant="'light-success'"
                        class="text-capitalize"
                      >
                        Applied
                      </b-badge>
                      <b-button
                        variant="primary"
                        v-else
                        @click="applyVacantShifts(data.item.id)"
                        >Apply</b-button
                      >
                    </template>
                  </b-table>
                </b-overlay>
              </b-col>
              <b-col cols="12" v-if="noDataTable === 0" class="text-center">
                <span>No data for preview</span>
              </b-col>
              <!-- Pagination -->

              <b-col
                class="
                  d-flex
                  align-items-center
                  justify-content-center justify-content-sm-start
                "
                cols="12"
                sm="6"
              >
                <span class="text-muted" v-if="pagination.totalRows !== 0"
                  >Showing {{ pagination.from }} to {{ pagination.to }} of
                  {{ pagination.totalRows }} entries</span
                >
              </b-col>

              <!-- Pagination -->

              <b-col
                class="
                  d-flex
                  align-items-center
                  justify-content-center justify-content-sm-end
                "
                cols="12"
                sm="6"
              >
                <b-pagination
                  v-model="currentPage"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.totalRows"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>

                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </b-card>
          <!--          <b-card>-->

          <!--            <b-row>-->

          <!--              <b-col cols="12">-->
          <!--                <h3>PCN</h3>-->
          <!--                <pcn-vacant-shift @clicked='getPCNShift'></pcn-vacant-shift>-->
          <!--              </b-col>-->
          <!--            </b-row>-->
          <!--          </b-card>-->
        </div>
        <b-card v-if="false">
          <div class="text-center p-5">
            <svg
              width="250"
              height="250"
              viewBox="0 0 160 160"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M80 0C124.183 0 160 35.817 160 80C160 124.183 124.183 160 80 160C35.817 160 0 124.183 0 80C0 35.817 35.817 0 80 0Z"
                fill="#F0F8FC"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M136.17 57.8711C138.52 57.8711 140.425 59.7766 140.425 62.1264C140.425 64.4762 138.52 66.3817 136.17 66.3817C133.819 66.3817 131.915 64.4762 131.915 62.1264C131.915 59.7766 133.819 57.8711 136.17 57.8711Z"
                fill="#DCEEF8"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M148.085 44.2539C151.375 44.2539 154.043 46.9211 154.043 50.2114C154.043 53.5016 151.375 56.1688 148.085 56.1688C144.795 56.1688 142.128 53.5016 142.128 50.2114C142.128 46.9211 144.795 44.2539 148.085 44.2539Z"
                fill="#DCEEF8"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M130.213 42.5547C131.153 42.5547 131.915 43.3164 131.915 44.2568C131.915 45.1972 131.153 45.9589 130.213 45.9589C129.272 45.9589 128.511 45.1972 128.511 44.2568C128.511 43.3164 129.272 42.5547 130.213 42.5547Z"
                fill="#DCEEF8"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M43.8595 40.457H116.533C120.545 40.457 123.797 43.7089 123.797 47.7209V120.394C123.797 124.406 120.545 127.658 116.533 127.658H43.8595C39.8476 127.658 36.5957 124.406 36.5957 120.394V47.7209C36.5957 43.7089 39.8476 40.457 43.8595 40.457Z"
                stroke="#171822"
                stroke-width="3"
              />
              <path
                d="M100.426 33.1914V52.5693"
                stroke="#1B9AAA"
                stroke-width="3"
                stroke-linecap="round"
              />
              <path
                d="M36.5957 67.1016H122.877"
                stroke="#171822"
                stroke-width="3"
              />
              <path
                d="M61.668 33.1914V52.5693"
                stroke="#1B9AAA"
                stroke-width="3"
                stroke-linecap="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M55.9746 91.3252H65.6597V81.6367H55.9746V91.3252Z"
                fill="#CCE6F4"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M55.9746 100.296C55.9746 99.3555 56.7321 98.5938 57.6767 98.5938H63.9576C64.9023 98.5938 65.6597 99.3555 65.6597 100.296V106.58C65.6597 107.521 64.9023 108.282 63.9576 108.282H57.6767C56.7321 108.282 55.9746 107.521 55.9746 106.58V100.296Z"
                fill="#1B9AAA"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M75.3533 83.3388C75.3533 82.3984 76.1107 81.6367 77.0554 81.6367H83.3362C84.2809 81.6367 85.0384 82.3984 85.0384 83.3388V89.6231C85.0384 90.5635 84.2809 91.3252 83.3362 91.3252H77.0554C76.1107 91.3252 75.3533 90.5635 75.3533 89.6231V83.3388Z"
                fill="#1B9AAA"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M75.3533 100.296C75.3533 99.3555 76.1107 98.5938 77.0554 98.5938H83.3362C84.2809 98.5938 85.0384 99.3555 85.0384 100.296V106.58C85.0384 107.521 84.2809 108.282 83.3362 108.282H77.0554C76.1107 108.282 75.3533 107.521 75.3533 106.58V100.296Z"
                fill="#1B9AAA"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M94.7319 83.3388C94.7319 82.3984 95.4979 81.6367 96.4341 81.6367H102.723C103.66 81.6367 104.426 82.3984 104.426 83.3388V89.6231C104.426 90.5635 103.66 91.3252 102.723 91.3252H96.4341C95.4979 91.3252 94.7319 90.5635 94.7319 89.6231V83.3388Z"
                fill="#1B9AAA"
              />
            </svg>
            <br />
            <div class="mt-lg-1 text-large">Please Add Vacant Shifts</div>
          </div>
        </b-card>
      </b-overlay>
    </b-modal>
    <b-modal
      id="modal-center"
      v-model="showSwapRequestModel"
      no-close-on-backdrop
      centered
      hide-footer
      size="xl"
      title="Swap Requests"
    >
      <b-overlay :show="swapRequestLoading" rounded="sm">
        <div
          v-if="swapRequestList.length === 0"
          class="text-center"
          style="height: 100px"
        >
          <span class="font-weight-700 mt-3">
            <b-alert variant="warning " class="p-2" show>
              <span class="text-dark">You haven't any swap request!</span>
            </b-alert>
          </span>
        </div>
        <div v-for="request in swapRequestList">
          <b-row>
            <b-col cols="10">
              <ul class="list-inline mb-1">
                <li class="list-inline-item">
                  <b-avatar
                    :src="
                      require('@/assets/images/portrait/small/avatar-s-5.jpg')
                    "
                    class="pull-up"
                  />
                </li>
                <li class="list-inline-item">
                  <span class="font-weight-700">{{
                    request.shift.assignee.first_name +
                    " " +
                    request.shift.assignee.last_name
                  }}</span>
                  wants to swap their shift with yours.
                </li>
              </ul>
            </b-col>
            <b-col cols="2">
              <div class="float-right">
                <b-button
                  size="23"
                  variant="outline-white"
                  v-b-tooltip.hover.top="'Accept'"
                  class="btn-icon bg-white text-primary"
                  @click="acceptSwapRequest(request.id)"
                >
                  <feather-icon icon="CheckSquareIcon" size="18" />
                </b-button>

                <b-button
                  size="23"
                  variant="outline-white"
                  v-b-tooltip.hover.top="'Reject'"
                  class="btn-icon bg-white text-danger ml-1"
                  @click="rejectSwapRequest(request.id)"
                >
                  <feather-icon
                    icon="XSquareIcon"
                    size="18"
                    variant="outline-danger"
                  />
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="5">
              <b-card>
                <div class="flex-centered mb-1">
                  <span
                    class="font-weight-700 mr-1"
                    v-if="request.swap_shift.service !== null"
                    >{{
                      request.swap_shift.service === null
                        ? "test"
                        : request.swap_shift.service.name
                    }}</span
                  >
                  <span>
                    <b-badge
                      v-if="request.swap_shift.is_covid_shift"
                      style=""
                      variant="danger"
                    >
                      <span class="text-white font-weight-700"> COVID-19 </span>
                    </b-badge>
                  </span>
                </div>
                <div class="mb-1">
                  <feather-icon
                    class="
                      cursor-pointer
                      e-round e-small
                      text-primary
                      font-weight-bolder
                    "
                    icon="CalendarIcon"
                    size="16"
                  />

                  <span class="ml-1">
                    {{
                      momentFormat(
                        new Date(request.swap_shift.start),
                        "dddd YYYY-MM-DD"
                      )
                    }}</span
                  >
                </div>
                <div>
                  <feather-icon
                    class="
                      cursor-pointer
                      e-round e-small
                      text-primary
                      font-weight-bolder
                    "
                    icon="ClockIcon"
                    size="16"
                  />

                  <span class="ml-1">
                    {{
                      momentFormat(
                        new Date(request.swap_shift.start),
                        "HH:mm"
                      ) +
                      "-" +
                      momentFormat(new Date(request.swap_shift.end), "HH:mm")
                    }}
                  </span>
                </div>
                <!--                {{momentFormat(new Date(data.StartTime),'dddd YYYY-MM-DD')}}-->
              </b-card>
            </b-col>
            <b-col cols="2" class="d-flex justify-content-center">
              <feather-icon
                class="mt-3 text-primary"
                icon="RepeatIcon"
                style="width: 30px; height: 30px"
              />
            </b-col>
            <b-col cols="5">
              <b-card>
                <div class="flex-centered mb-1">
                  <span class="font-weight-700 mr-1">{{
                    request.shift.service === null
                      ? ""
                      : request.shift.service.name
                  }}</span>
                  <span>
                    <b-badge
                      v-if="request.shift.is_covid_shift"
                      style=""
                      variant="danger"
                    >
                      <span class="text-white font-weight-700 f">
                        COVID-19
                      </span>
                    </b-badge>
                  </span>
                </div>
                <div class="mb-1">
                  <feather-icon
                    class="
                      cursor-pointer
                      e-round e-small
                      text-primary
                      font-weight-bolder
                    "
                    icon="CalendarIcon"
                    size="16"
                  />

                  <span class="ml-1">
                    {{
                      momentFormat(
                        new Date(request.shift.start),
                        "dddd YYYY-MM-DD"
                      )
                    }}</span
                  >
                </div>
                <div>
                  <feather-icon
                    class="
                      cursor-pointer
                      e-round e-small
                      text-primary
                      font-weight-bolder
                    "
                    icon="ClockIcon"
                    size="16"
                  />

                  <span class="ml-1">
                    {{
                      momentFormat(new Date(request.shift.start), "HH:mm") +
                      "-" +
                      momentFormat(new Date(request.shift.end), "HH:mm")
                    }}
                  </span>
                </div>
                <!--                {{momentFormat(new Date(data.StartTime),'dddd YYYY-MM-DD')}}-->
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BAlert,
  BCard,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormInvalidFeedback,
  BFormTextarea,
  BInputGroupPrepend,
  BSidebar,
  BTab,
  BTabs,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import quickInfoTContentmplate from "./eventView/content";
import quickInfoHeaderTemplate from "./eventView/header";
import quickInfoFooterTemplate from "./eventView/footer";
import weekTemplate from "@/views/my-shifts/eventsTemplates/weekTemplate";
import monthTemplate from "@/views/my-shifts/eventsTemplates/monthTemplate";

import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import settings from "@/apis/modules/settings";
import shift from "@/apis/modules/shift";
import ErrorMessages from "@/mixins/ErrorMessages";
import SuccessMessage from "@/mixins/SuccessMessage";
import MomentMixin from "@/mixins/MomentMixin";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Vue from "vue";
import { extend } from "@syncfusion/ej2-base";
import {
  SchedulePlugin,
  Day,
  Week,
  Month,
  DragAndDrop,
  Agenda,
  TimelineViews,
  TimelineMonth,
  Resize,
  Print,
} from "@syncfusion/ej2-vue-schedule";
import {
  DatePickerPlugin,
  ChangeEventArgs,
} from "@syncfusion/ej2-vue-calendars";
import leave from "@/apis/modules/leave";
Vue.use(SchedulePlugin);
Vue.use(DatePickerPlugin);
import flatPickr from "vue-flatpickr-component";
import practice from "@/apis/modules/practice";
import Filter from "@/mixins/FilterMixin";
import ProfileAPI from "@/apis/modules/profile";
import PcnVacantShift from "@/views/my-shifts/pcnVacantShift";
import moment from "moment";
export default {
  components: {
    PcnVacantShift,
    weekTemplate,
    flatPickr,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    vSelect,
    BInputGroupPrepend,
    BCard,
    BTabs,
    BTab,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BForm,
    BFormCheckbox,
    BFormDatepicker,
    BFormInvalidFeedback,
    BSidebar,
    BFormTextarea,
    VBTooltip,
    BOverlay,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],

  data() {
    return {
      calendarLoading: false,
      isActive: true,
      show_vacant: false,
      show_vacant_saved: null,
      appliedShifts: [],
      selectedShiftType: {
        title: "All Shifts",
        value: "",
      },
      swapRequestListLength: 0,
      currentPage: 1,
      pagination: {
        perPage: "",
        pageOptions: "",
        totalRows: "",
        from: "",
        to: "",
      },
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filters: {},
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "date",
          label: "Date",
        },
        {
          key: "programme",
          label: "PROGRAMME",
        },

        {
          key: "subject",
          label: "SERVICE",
        },
        {
          key: "time",
          label: "Time",
        },
        {
          key: "action",
          label: " ",
        },

        // {
        //   key: 'action',
        //   label: '',
        // },
      ],
      currentUser: {},
      vacantShifts: {},
      mpShifts: {},
      isChecked: false,
      respondPCN: {},
      respondMP: {},
      sheduleDataMap1: {},
      sheduleDataFinalArray: {},
      noDataTable: "",
      showSwapRequestModel: false,
      swapRequestList: [],
      swapRequestLoading: false,
      tableLoading: false,
      selectedDateForFilter: "",
      showApplyVacantModel: false,
      vacantShiftsLoading: false,
      eventSettings: { dataSource: "" },
      selectedDate: new Date(),
      items: [],
      currentView: "Week",
      startHour: "08:00",
      currentUserId: "",
      timeScale: {
        enable: true,
        interval: 60,
        slotCount: 2,
      },
      query: {
        value: "",
        start: null,
        end: null,
      },
      quickInfoTemplates: {
        header() {
          return { template: quickInfoHeaderTemplate };
        },
        content() {
          return { template: quickInfoTContentmplate };
        },
        footer() {
          return { template: quickInfoFooterTemplate };
        },
      },
      weekTemplate() {
        return { template: weekTemplate };
      },
      monthTemplate() {
        return { template: monthTemplate };
      },
      draftLength: "",
    };
  },
  provide: {
    schedule: [
      Agenda,
      TimelineViews,
      Month,
      TimelineMonth,
      Day,
      Week,
      Resize,
      DragAndDrop,
      Print,
    ],
  },
  watch: {
    "query.value"(values) {
      this.getUsers();
    },
    selectedShiftType(value) {
      this.getselectedShiftData(value);
    },
    eventSettings() {},
  },
  // computed: {
  //   ...mapGetters(["currentUser"]),
  // },

  methods: {
    async stateChange(id) {
      try {
        let message = "";
        if (this.show_vacant === true) {
          message = `Are you sure you want to show vacant shifts on my calendar?`;
        } else {
          message = `Are you sure you don't want to Show vacant shifts on my calendar?`;
        }

        await settings.changeStateMyCalendar();
        // this.updateUserState(this.currentUser)
        if (this.show_vacant === true) {
          await this.showInCalender();
          this.calendarLoading = false;
        } else {
          await this.showOnlyVacant();
          this.calendarLoading = false;
        }
        // await this.showInCalender();
        this.isActive = true;
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },
    async updateUserInfo() {
      try {
        const payload = {
          show_vacant_shift: this.show_vacant_shift,
        };
        //  const response = await staffBank.updateSalariedStaffPersonalInfo(this.$route.params.id, payload)
        //   this.$emit('updatedDetails')
      } catch (e) {
        this.convertAndNotifyError(e);
      }
    },
    async getVacantShiftState() {
      try {
        this.currentUser = (await ProfileAPI.getCurrentUser()).data.data;
        this.show_vacant = this.currentUser.show_vacant_shift ? true : false;
        this.show_vacant_saved = this.currentUser.show_vacant_shift
          ? true
          : false;
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },
    cancelData() {
      this.show_vacant = !this.show_vacant;
      this.isActive = true;
    },
    getPCNShift(value) {
      this.respondPCN = value;
    },
    dataBinding(val) {
      const start_date = moment(
        val.query.params.find((x) => x.key === "StartDate").value
      ).format("YYYY-MM-DD");
      const end_date = moment(
        val.query.params.find((x) => x.key === "EndDate").value
      ).format("YYYY-MM-DD");
      this.query.start = start_date;
      this.query.end = end_date;
      this.query.value = `${start_date}_${end_date}`;
    },
    async acceptSwapRequest(id) {
      try {
        this.swapRequestLoading = true;
        await shift.swapRequestAccept(id);
        this.showSuccessMessage("Accept Swap Request Successful");
        await this.getSwapRequest();
        this.swapRequestLoading = false;
        await this.getUsers();
      } catch (error) {
        this.convertAndNotifyError(error);
        this.swapRequestLoading = false;
      }
    },
    getselectedShiftData(value) {
      switch (value.value) {
        case "approved":
          this.eventSettings = { dataSource: this.appliedShifts };
          break;
        case "vacants":
          this.eventSettings = {
            dataSource: this.save_shifts.filter(
              (x) => x.state === "published" && x.assignee_id !== null
            ),
          };
          break;
        case "filleds":
          this.eventSettings = {
            dataSource: this.save_shifts.filter(
              (x) => x.state === "published" && x.assignee_id !== null
            ),
          };
          break;
        case "cancelled":
          this.eventSettings = {
            dataSource: this.save_shifts.filter((x) => x.state === "cancel"),
          };
          break;
        case "all":
          this.eventSettings = { dataSource: this.save_shifts };
          break;
      }
    },

    async rejectSwapRequest(id) {
      try {
        this.swapRequestLoading = true;
        await shift.swapRequestReject(id);
        this.showSuccessMessage("Reject Swap Request Successful");
        await this.getSwapRequest();
        this.swapRequestLoading = false;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.swapRequestLoading = false;
      }
    },
    async openSwapRequestMode() {
      this.showSwapRequestModel = true;
    },
    async getSwapRequest() {
      try {
        this.swapRequestLoading = true;
        const response = await shift.getSwapRequestList();
        this.swapRequestList = response.data.data;
        this.swapRequestListLength = this.swapRequestList.length;
        this.swapRequestLoading = false;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.swapRequestLoading = false;
      }
    },
    async applyVacantShifts(id) {
      try {
        this.$swal({
          title: "Are you sure you want to apply this vacant shift?",
          text: "",
          imageUrl: require("@/assets/images/icons/publish.png"),
          imageWidth: 80,
          imageHeight: 80,
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No, go back",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            await shift.applyVacantShift(id);
            this.showSuccessMessage("Shift Applied Successfully");
            await this.getVacantShiftApplications();
            this.$refs.table.refresh();
          }
        });
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },

    async applyMPVacenshitsFromCell(id) {
      try {
        this.calendarLoading = true;
        await shift.applyVacantShift(id);
        this.showSuccessMessage("Shift Applied Successfully");
        // await this.getAppliedShifts()
        await this.showInCalender();
      } catch (e) {
        this.convertAndNotifyError(e);
      }
      this.calendarLoading = false;
    },

    async applyPCNVcaneshitsFromCell(id) {
      try {
        this.calendarLoading = true;
        await shift.applyPCNVacantShift(id);
        this.showSuccessMessage("Shift Applied Successfully");
        // await this.getAppliedShifts()
        await this.showInCalender();
      } catch (e) {
        this.convertAndNotifyError(e);
      }
      this.calendarLoading = false;
    },
    async getVacantShiftApplications() {
      this.tableLoading = true;
      try {
        this.respondMP = await shift.getApplicationListVacantShifts(
          this.filterQuery
        );
        this.noDataTable = this.respondMP.data.data.length;

        let dataArray = this.respondMP.data.data.map((x) => ({
          id: x.id,
          date: this.momentFormat(x.start, "DD-MMM-YY"),
          subject: x.service === null ? "N/A" : x.service.name,
          programme: x.pcn_programme ? x.pcn_programme.name : "N/A",
          rate: x.salary_staff_rate ? x.salary_staff_rate : 0,
          time:
            `${this.momentFormat(x.start, "HH:mm")}` +
            " " +
            `${this.momentFormat(x.end, "HH:mm")}`,
          applications: `${this.vacuntShiftAppliedCheck(
            x.applications,
            this.currentUser.id
          )}`,
          created_by: x.created_by,
        }));
        const paginationResponse = this.respondMP.data.meta;
        this.currentPage = paginationResponse.current_page;
        this.pagination.totalRows = paginationResponse.total;
        this.pagination.perPage = paginationResponse.per_page;
        this.pagination.from = paginationResponse.from;
        this.pagination.to = paginationResponse.to;

        this.tableLoading = false;
        return dataArray;
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },
    openApplyShiftsModel() {
      this.showApplyVacantModel = !this.showApplyVacantModel;
    },
    openpopup(agrs) {
      if (!agrs.data.Guid) {
        agrs.cancel = true;
        this.selectedCell = agrs;
        this.createShift();
      }
    },
    async onPopUpOpen(agrs) {
      // if (this.isAddNewShiftSidebarActive === true){
      //   agrs.cancel= true
      //   alert('jksjskjsj')
      // }
    },

    async onEventClick(agrs) {
      this.selectedCell = await agrs;
    },
    async onCellClick(agrs) {
      agrs.cancel = true;
      this.selectedCell = agrs;
    },
    async showOnlyVacant() {
      this.calendarLoading = true;
      try {
        await this.getAllVacantShifts();
        this.sheduleDataFinalArray = this.sheduleDataMap1
          .concat(this.appliedShifts)
          .map((level, i) => {
            switch (level.state) {
              case "draft":
                // level.color ='#605d5d'
                level.color = "#B2B2B2";
                level.border = "black";
                //level.width = '100px'
                break;
              case "cancel":
                // level.color ='#605d5d'
                level.color = "#B2B2B2";
                level.border = "black";
                //level.width = '100px'
                break;
              case "published":
                if (level.user_id) {
                  level.color = "#00C49A";
                } else {
                  level.color = "";
                }
                break;
              case "pending":
                level.color = "#FFB133";
                break;
            }
            // // ...
            return level;
          });
        this.draftLength = this.sheduleDataFinalArray.filter(
          (m) => m.state === "draft"
        ).length;
        this.save_shifts = this.sheduleDataFinalArray;
        this.eventSettings = { dataSource: this.sheduleDataFinalArray };
      } catch (e) {
        this.convertAndNotifyError(error);
      }

      this.calendarLoading = false;
    },

    onEventRendered: function (args) {
      let categoryColor = args.data.color;
      if (!args.element || !categoryColor) {
        return;
      }
      args.element.style.backgroundColor = categoryColor;
    },
    onDateChange: function (args) {
      this.selectedDate = args.value;
    },

    async getUsers() {
      try {
        if (!this.query.start || !this.query.end) return;
        const userResponse = await shift.myShift(
          this.query.start,
          this.query.end
        );
        // await this.getAppliedShifts();
        this.sheduleDataMap1 = userResponse.data.data.map((x) => ({
          Id: x.id,
          EndTime: new Date(x.end),
          StartTime: new Date(x.start),
          starTimeForEdit: new Date(x.start),
          endTimeForEdit: new Date(x.end),
          user_id: x.assignee.id,
          user: x.assignee,
          group_id: x.job_role.id,
          Subject: x.service === null ? "" : x.service.name,
          service: x.service === null ? [] : x.service,
          shift_type: x.shift_type === null ? [] : x.shift_type,
          state: x.state,
          job_role: x.job_role,
          break_minutes: x.break_minutes,
          note: x.note,
          rate: x.rate,
          IsAllDay: false,
          is_covid_shift: x.is_covid_shift,
          is_pcn: x.p_c_n_id ? true : false,
          pcn: x.pcn,
          programme: x.pcn_programme,
          location: x.pcn_location,
          practice: x.practice,
        }));
        if (this.show_vacant === true) {
          this.showInCalender();
        } else {
          this.showOnlyVacant();
        }
        this.loading = false;
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },
    filterQueryUpdate() {
      // this.$refs.table.refresh();
    },

    async showInCalender() {
      this.calendarLoading = true;
      try {
        await this.getAllVacantShifts();
        this.sheduleDataFinalArray = this.sheduleDataMap1
          .concat(this.vacantShifts, this.appliedShifts)
          .map((level, i) => {
            switch (level.state) {
              case "draft":
                // level.color ='#605d5d'
                level.color = "#B2B2B2";
                level.border = "black";
                //level.width = '100px'
                break;
              case "cancel":
                // level.color ='#605d5d'
                level.color = "#B2B2B2";
                level.border = "black";
                //level.width = '100px'
                break;
              case "published":
                if (level.user_id) {
                  level.color = "#00C49A";
                } else {
                  level.color = "";
                }
                break;
              case "pending":
                level.color = "#FFB133";
                break;
            }
            // // ...
            return level;
          });
        this.draftLength = this.sheduleDataFinalArray.filter(
          (m) => m.state === "draft"
        ).length;
        this.save_shifts = this.sheduleDataFinalArray;
        this.eventSettings = { dataSource: this.sheduleDataFinalArray };
      } catch (e) {
        this.convertAndNotifyError(error);
      }
      this.calendarLoading = false;
    },

    async getAllVacantShifts() {
      try {
        this.respondMP = await shift.getApplicationListVacantShifts(
          this.filterQuery
        );
        let respond = this.respondMP.data.data.map((x) => ({
          Id: x.id,
          EndTime: new Date(x.end),
          StartTime: new Date(x.start),
          starTimeForEdit: new Date(x.start),
          endTimeForEdit: new Date(x.end),
          user: x.assignee,
          Subject: x.service === null ? "" : x.service.name,
          service: x.service === null ? [] : x.service,
          shift_type: x.shift_type === null ? [] : x.shift_type,
          job_role: x.job_role,
          break_minutes: x.break_minutes,
          note: x.note,
          rate: x.rate,
          IsAllDay: false,
          is_covid_shift: x.is_covid_shift,
          is_pcn: x.p_c_n_id ? true : false,
          pcn: x.pcn,
          programme: x.pcn_programme,
          location: x.pcn_location,
          practice: x.practice,
          applications: `${this.vacuntShiftAppliedCheck(
            x.applications,
            this.currentUser.id
          )}`,
          state: x.applications.length > 0 ? "pending" : "published",
        }));
        this.vacantShifts = respond.filter((item) => {
          return item.applications === "undefined";
        });
        this.appliedShifts = respond.filter((item) => {
          return item.applications === "Yes";
        });

        // return this.vacantShifts;
      } catch (e) {
        this.convertAndNotifyError(error);
        this.calendarLoading = false;
      }
      this.calendarLoading = false;
    },

   async updateState() {
      if (!this.showApplyVacantModel) {
        if (this.show_vacant === true) {
         await this.showInCalender();
          this.calendarLoading = false;
        } else {
         await this.showOnlyVacant();
          this.calendarLoading = false;
        }
      }
    },
  },

  async mounted() {
    this.$refs.scheduler.ej2Instances.firstDayOfWeek = 1;
    // sessionStorage.setItem('practice_id', this.$route.query.practice_id)
    // await this.$root.$on('indexComponentFormCancel', () => {
    //   // this.getUsers()
    // })
    // await this.getCurrentUser()
    await this.getVacantShiftState();
    await this.getUsers();
    await this.getSwapRequest();

    // this.getVacantShiftCancelState()
    // this.getVacantShiftState()
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss"; /*print style*/
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style>
div#dropdown-1 .btn-outline-danger {
  border: none !important;
}

[dir] .btn.btn-icon {
  padding: 0.715rem 0.736rem;
  background-color: white;
}

[dir] .e-quick-popup-wrapper .e-event-popup .e-popup-header {
  background-color: white;
}

[dir="ltr"] .vs--single .vs__dropdown-toggle {
  background-color: white;
}
.swapButton {
  background-color: white;
}

.text-capitalize {
  margin-left: 6px;
  margin-top: 9px;
  margin-bottom: 9px;
}
</style>
