<template>
  <div>

    <b-row class="padingHeader">
      <b-col cols="12" md="5" class="header_state">
        <span class="" v-if="data.state ==='published'&& data.user === null"> <span class="vacant">•</span>Vacant</span>
        <span class="" v-if="data.state ==='published'&& data.user !== null"> <span class="filled">•</span>Filled</span>
        <span class="" v-if="data.state ==='pending'"> <span class="filled">•</span>Pending</span>
        <span class="" v-if="data.state ==='cancel'"> <span class="filled">•</span>Cancel</span>
<!--        <span>{{}}</span>-->

<!--        <span>-->

<!--        <b-badge size="sm" v-if="data.service.type ==='clinical'" :variant="'light-success'" class="text-capitalize">-->
<!--         {{data.service.type}}-->
<!--        </b-badge>-->
<!--        <b-badge v-else :variant="'light-info'" class="text-capitalize">-->
<!--          {{data.service.type}}-->
<!--        </b-badge>-->
<!--        </span>-->
<!--        <span class=""  > {{data.State.charAt(0).toUpperCase() + data.State.substring(1)}}</span>-->
      </b-col>
<!--      <b-col cols="12" md="2" class="header_state">-->
<!--        <b-button size="sm"  variant="primary" >publish</b-button>-->
<!--        &lt;!&ndash;        <span class="">.</span>&ndash;&gt;-->
<!--        &lt;!&ndash;        <span class=""  > {{data.State.charAt(0).toUpperCase() + data.State.substring(1)}}</span>&ndash;&gt;-->
<!--      </b-col>-->
      <b-col cols="12" md="7">
        <div class="float-right">
          <feather-icon v-if="data.state === 'draft'"
                        class="ml-1 cursor-pointer e-round e-small text-primary font-weight-bolder"
                        icon="UploadIcon"
                        size="16"
                        v-b-tooltip.hover.top="'Publish'"

                        @click="publishEvent(data.Id)"
          />
          <feather-icon v-if="data.state ==='pending' || data.user !== null"
              class="ml-1 cursor-pointer e-round e-small text-primary font-weight-bolder"
              icon="RepeatIcon"
              size="16"
                        v-b-tooltip.hover.top="'Swap'"

              @click="swapModelOpen()"
          />
<!--          <feather-icon v-if="data.State !=='cancelled'"-->
<!--              class="ml-1 cursor-pointer e-round e-small text-primary font-weight-bolder"-->
<!--              icon="CopyIcon"-->
<!--              size="16"-->
<!--                        v-b-tooltip.hover.top="'Duplicate'"-->
<!--                        @click="duplicateEvent()"-->
<!--          />-->
<!--          <feather-icon-->

<!--              class="ml-1 cursor-pointer e-round e-small text-primary font-weight-bolder"-->
<!--              icon="TrashIcon"-->
<!--              size="16"-->
<!--              v-b-tooltip.hover.top="'Delete'"-->
<!--              @click="deleteShift()"-->
<!--          />-->
          <feather-icon
              class="ml-1 cursor-pointer e-round e-small text-primary font-weight-bolder"
              icon="XSquareIcon"
              size="16"
              v-if="data.state ==='pending' || data.user !== null"
              v-b-tooltip.hover.top="'Cancel'"
              @click="cancelShiftFilled()"
          />

          <feather-icon
              class="ml-1 cursor-pointer e-close e-control e-btn e-lib e-flat e-round e-small e-icon-btn"
              v-b-tooltip.hover.top="'close'"
              icon="XIcon"
              size="16"
          />
        </div>
      </b-col>

    </b-row>
    <b-modal
        id="modal-center"
        v-model="showSwapModel"
        no-close-on-backdrop
        centered
        hide-footer
        size="xl"
        title="Swap Shift"
    >
      <b-overlay
          :show="swapLoading"
          rounded="sm"
      >
        <h6>Your shift:</h6>
        <b-card>
          <b-row>
            <b-col cols="4">
              <span v-if="data.service.name">{{data.service.name}}</span>
              <span v-else>N/A</span>


            </b-col>
            <b-col cols="4">
              <feather-icon
                  class="ml-1 cursor-pointer e-round e-small text-primary font-weight-bolder"
                  icon="CalendarIcon"
                  size="16"

                  />

              <span class="ml-1">{{momentFormat(new Date(data.StartTime),'dddd YYYY-MM-DD')}}</span>
            </b-col>
            <b-col cols="4">
              <feather-icon
                  class="ml-1 cursor-pointer e-round e-small text-primary font-weight-bolder"
                  icon="ClockIcon"
                  size="16"

              />
              <span class="ml-1">{{momentFormat(data.StartTime,'HH:mm:ss')}}</span>
            </b-col>
          </b-row>
        </b-card>
       <h6> Swap with:</h6>
        <b-card>
          <b-row>
            <b-col cols="12">

              <b-table
                  :selectOnCheckboxOnly="true"
                  :current-page="currentPage"
                  :per-page="pagination.perPage"
                  :fields="fields"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :items="getAbleToSwapList"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"

                  hover
                  ref="table"

                  class="mobile_table_css"
                  responsive
              >


                <template #cell(user)="data">
<!--                  <b-badge v-if="data.item.status ==='Requested'" class="ml-1" style="padding:8px" variant="warning" >-->
<!--                    <span class=""> {{data.item.status}} </span>-->
<!--                  </b-badge>-->
<!--                  <b-badge v-if="data.item.status ==='Approved'" class="ml-1" style="padding:8px" variant="success" >-->
<!--                    <span class=""> {{data.item.status}} </span>-->
<!--                  </b-badge>-->
<!--                  <b-badge v-if="data.item.status ==='Rejected'" class="ml-1" style="padding:8px" variant="danger" >-->
<!--                    <span class=""> {{data.item.status}} </span>-->
<!--                  </b-badge>-->
<!--                  <b-badge v-if="data.item.status ==='Cancelled'" class="ml-1" style="padding:8px" variant="info" >-->
<!--                    <span class=""> {{data.item.status}} </span>-->
<!--                  </b-badge>-->
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <b-avatar :src="require('@/assets/images/portrait/small/avatar-s-5.jpg')" class="pull-up " />
                    </li>
                    <li class="list-inline-item"> {{data.item.user}} </li>
                    <b-badge class="ml-1" style="padding:8px" variant="light-info">
                      <!--              <span class="text-dark font-weight-700"> {{data.item.role}} </span>-->
                    </b-badge>
                  </ul>


                </template>
                <template #cell(action)="data">

                  <b-button

                            size="sm"
                            variant="primary"
                            class="btn-icon bg-white  ml-1"
                            @click=" swapShiftFunction(data.item.id)"

                  >
<!--                    <feather-icon icon="XSquareIcon" size="18" variant="outline-danger"/>-->
                    Send Request
                  </b-button>



                </template>

              </b-table>


            </b-col>
            <b-col cols="12" v-if="noDataTable === 0" class="text-center">
              <span >There are no shifts available to swap. Contact your practice admin to discuss the shift adjustments</span>
            </b-col>
            <!-- Pagination -->



            <!-- Pagination -->


          </b-row>

        </b-card>





      </b-overlay>

    </b-modal>
  </div>
</template>
<!--intl: new Internationalization(),-->
<script>
import {
  BAlert, BBadge,
  BButton, BButtonGroup,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BFormGroup,
  BLink,
  BListGroup,
  BListGroupItem, BModal,
  BRow, BSidebar, VBTooltip,
    BOverlay,
    BTable,
    BAvatar
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import shift from '@/apis/modules/shift'
import Ripple from 'vue-ripple-directive'

import MomentMixin from '@/mixins/MomentMixin'
import leave from '@/apis/modules/leave'



  export default {
    name: 'header',
    directives: {
      Ripple,
      'b-tooltip': VBTooltip,
    },
    mixins: [MomentMixin],
    components: {
      BAvatar,
      BRow,
      BCol,
      BCard,
      BCardText,
      BLink,
      BCardTitle,
      BListGroup,
      BListGroupItem,
      BCardBody,
      BFormGroup,
      BButton,
      BSidebar,
      BModal,
      BAlert,
      BButtonGroup,
      ToastificationContent,
      BBadge,
      BOverlay,
      BTable

    },

    data() {
      return {
        swapLoading:false,
        showSwapModel:false,
        hide:false,
        data: {},



        currentPage: 1,
        pagination: {
          perPage: '',
          pageOptions: '',
          totalRows:'' ,
          from:'',
          to:''
        },
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          {
            key: 'user',
            label:'User',
          },
          {
            key:'service',
            label:'Service'

          },
          {
            key: 'date',
            label:'Date',
          },
          {
            key: 'time',
            label: 'Time',
          },

          {
            key: 'action',
            label:' ',
          },


          // {
          //   key: 'action',
          //   label: '',
          // },

        ],
        items: [],
        noDataTable:'',

        jobRoleOption: [],
        systemRoleOption:[],
        user_name:'',
        user_email:'',
        user_id:'',
        modelShow: false,
        email: '',
        system_role_ids: [],
        job_role_id: '',
      };
    },
    methods: {
      async swapShiftFunction(id){
        try {
          const payload ={
            shift_id:this.data.Id,
            swap_shift_id:id
          }
          await shift.swapShift(payload)
          this.$refs.table.refresh()

        }catch (error){
          this.convertAndNotifyError(error)
        }

      },
      async getAbleToSwapList(){
        try {
          const Response = await shift.getSwapShift(this.data.Id,this.data.group_id)

          const filterArray = await Response.data.data.filter(x =>x.received_swap_requests.length === 0)


          this.noDataTable =filterArray.length

          let dataArray = filterArray.map((x) => ({
            id: x.id,
            time: `${this.momentFormat(x.start,'HH:mm:ss')}`+` -` +`${ this.momentFormat(x.end,'HH:mm:ss')}`,
            date: this.momentFormat(x.start,'dddd YYYY-MM-DD'),
            user: x.assignee.first_name +` `+ x.assignee.last_name + ' -'+ x.id ,
            service:x.service === null? 'N/A':x.service.name,
          }))


          // const paginationResponse = Response.data.meta
          // this.currentPage = paginationResponse.current_page
          // this.pagination.totalRows = paginationResponse.total
          // this.pagination.perPage= paginationResponse.per_page
          // this.pagination.from = paginationResponse.from
          // this.pagination.to = paginationResponse.to

          return dataArray






        }catch (error){
          this.convertAndNotifyError(error)
        }
      },
      async swapModelOpen(){
        this.showSwapModel = true


      },
      async publishEvent(id){
        this.$swal({
          title: 'Are you sure you want to publish rota?',
          text: "Staff whose shifts have changed will be notified.",
          showCancelButton: true,
           imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
          confirmButtonText: 'Yes, publish',
          cancelButtonText: "No, go back",
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            this.$swal({
              icon: 'success',
              title: 'Published!',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            try {
              await shift.publishData({'shift_ids':[id]})
              this.$parent.$parent.$parent.getUsers()
              // this.deleteShiftTost('success','top-center', 'Shift was Published!')
              this.showSuccessMessage('Shift was Published!')
            }catch (error){
              this.convertAndNotifyError(error)
            }

          }
        })
      },
      async editEvent(){
        this.$root.$emit('indexComponentFormEventTemplate') //like this
      },
      async duplicateEvent(){
        this.$root.$emit('indexComponentFormEventTemplateDuplicate') //like this
      },

      async deleteShift() {


        this.$swal({
          title: 'Are you sure you want to delete this shift?',
          text: "This action cannot be reverted.'",
          imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: "No, go back",
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            try {
              await shift.deleteEvent(this.data.Id)
              this.$parent.$parent.$parent.getUsers()
             // this.deleteShiftTost('success','top-center', 'Shift was deleted!')
              this.showSuccessMessage('Shift was deleted!')
            }catch (error){
              this.convertAndNotifyError(error)
            }

          }
        })
      },
      async cancelShiftFilled(){
        this.$swal({
          text: "This action cannot be reverted.",
          title: 'Are you sure you want to cancel this shift?',
          imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
          showCancelButton: true,
          confirmButtonText: 'Yes, cancel',
          cancelButtonText: "No, go back",
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then( async result => {
          if (result.value) {

            const response = await shift.cancelSift(this.data.Id)


            if(response.data.error) {
              this.showErrorMessage("Can't Cancel This Shift")
            }else {
              // this.deleteShiftTost('success','top-center', 'Shift was canceled!')
              this.showSuccessMessage('Shift was canceled!')
              this.$parent.$parent.$parent.getUsers()
              
              // this.$swal({
              //   icon: 'success',
              //   title: 'Canceled',
              //   customClass: {
              //     confirmButton: 'btn btn-success',
              //   },
              // })
              this.$root.$emit('indexComponentFormCancel')
            }



          }
        })

      },
      deleteShiftTost(variant, position, title) {
        this.$toast({
              component: ToastificationContent,
              props: {
                title: title,
                icon: 'CheckCircleIcon',
                variant,
              },
            },
            {
              position,
            })
      },
    },
mounted () {

}
  }
</script>

<style >
.vacant{

  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  margin-right: 2px;
  /* identical to box height, or 164% */


  /* Secondary */

  color: #4361EE;
}

.e-popup-header{
  background-color: white !important;
}
.header_state{
  font-size: 18px;
}
.padingHeader {
  padding: 4px!important;
}
.filled{
  /*position: absolute;*/
  /*width: 45px;*/
  /*height: 23px;*/
  /*left: 20px;*/
  /*top: calc(50% - 23px/2 - 121px);*/

  /*font-family: Mulish;*/
  /*font-style: normal;*/
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  margin-right: 2px;
  /* identical to box height, or 164% */


  /* Secondary */

  color: #00C49A;

}

</style>
